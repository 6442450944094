import React, { lazy } from 'react';
import logo from './logo.svg';
import './App.css';
import Home from './Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Evolvefilm from './video pages/evolvefilm';
import Upstox from './video pages/upstox';
import Showreel from './video pages/showreel';
import Muirak from './video pages/muirak';
import Prillmodernrelationeasy from './video pages/prillmodernrelationeasy';
import Sins from './video pages/sins';
import Prillmodernday from './video pages/prillmodrenday';
import Kood from './video pages/kood';
import V1Resolve from './video pages/v1resolve';
import PrillMaking from './video pages/prillmaking';
import One from './Recent Pages/one';
import Two from './Recent Pages/two';
import Three from './Recent Pages/three';
import Four from './Recent Pages/four';

const OneComponent = lazy(() => import("./Recent Pages/one"));
const TwoComponent = lazy(() => import("./Recent Pages/two"));
const ThreeComponent = lazy(() => import("./Recent Pages/three"));
const FourComponent = lazy(() => import("./Recent Pages/four"));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="evolvefilm" element={<Evolvefilm />} />
          <Route path="upstox" element={<Upstox />} />
          <Route path="showreel" element={<Showreel />} />
          <Route path="muirak" element={<Muirak />} />
          <Route path="prillmodernrelationeasy" element={<Prillmodernrelationeasy />} />
          <Route path="sins" element={<Sins />} />
          <Route path="prillmodernday" element={<Prillmodernday />} />
          <Route path="kood" element={<Kood />} />
          <Route path="showreel" element={<Showreel />} />
          <Route path="v1resolve" element={<V1Resolve />} />
          <Route path="prillmaking" element={<PrillMaking />} />
          <Route path="one" element={<OneComponent />} />
          <Route path="two" element={<TwoComponent />} />
          <Route path="three" element={<ThreeComponent />} />
          <Route path="four" element={<FourComponent />} />
        </Routes>
      </BrowserRouter>,
    </div>
  );
}

export default App;


/*

        <div className="gif-container">
          <div className="gif gif-container-1" onClick={()=>videoRedirect(videoLinks[0])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Organic Cinema Upstox The First Step.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Upstox</p>
          </div>
          <div className="gif gif-container-2" onClick={()=>videoRedirect(videoLinks[1])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/oc.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Showreel</p>
          </div>
          <div className="gif gif-container-3" onClick={()=>videoRedirect(videoLinks[2])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Muirak,The artist.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Muirak</p>
          </div>
          <div className="gif gif-container-4" onClick={()=>videoRedirect(videoLinks[3])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Prill Do you think Modern Relationships Are Easy.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Prill Modern</p>
          </div>
          <div className="gif gif-container-5" onClick={()=>videoRedirect(videoLinks[4])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/sins.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">SINS</p>
          </div>
          <div className="gif gif-container-6" onClick={()=>videoRedirect(videoLinks[5])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/prill ad.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Prill</p>
          </div>
          <div className="gif gif-container-7" onClick={()=>videoRedirect(videoLinks[6])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/koodu.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">KOOD</p>
          </div>
          <div className="gif gif-container-8" onClick={()=>videoRedirect(videoLinks[7])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/evolve.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Evolve</p>
          </div>
          <div className="gif gif-container-9" onClick={()=>videoRedirect(videoLinks[8])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/lic ipo.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">V1 Resolve</p>
          </div>
          <div className="gif gif-container-10" onClick={()=>videoRedirect(videoLinks[9])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Prill Making.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Prill Making</p>
          </div>
        </div>

*/