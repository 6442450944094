import React from 'react'
import './video.css'

const Muirak = () => {
  return (
    <div className='.div'>
        <h1 className='.h1'>MUIRAK</h1>
        <iframe src="https://drive.google.com/file/d/19J-l6cDagG6lZqxyVTm6-GR9Q6LHr77k/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default Muirak