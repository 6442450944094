import React from 'react'
import './video.css'

const V1Resolve = () => {
  return (
    <div className='.div'>
        <h1 className='.h1'>Upstox women's day</h1>
        <iframe src="https://drive.google.com/file/d/1_eSdyuIKL1jikF5OYsd9x-oLLgGqELgn/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default V1Resolve