import React from 'react'
import './video.css'

const Prillmodernday = () => {
  return (
    <div className='.div'>
        <h1 className='.h1'>PRIL MODERN DAY RELATIONSHIP</h1>
        <iframe src="https://drive.google.com/file/d/1h452yGvOjDzzka6-xF8dEvtw4ZUzn22s/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default Prillmodernday