import React from 'react'
import './video.css'

const Evolvefilm = () => {
  return (
    <div className='.div'>
        <h1 className='.h1'>EVOLVE FILM</h1>
        <iframe src="https://drive.google.com/file/d/1quA4Rq6asP_a36AqkNycobOUql9a1orO/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default Evolvefilm