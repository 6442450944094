import React from 'react'
import './video.css'

const Kood = () => {
  return (
    <div className='.div'>
      <h1 className='.h1'>KOOD</h1>
      <iframe src="https://drive.google.com/file/d/1PSPhdTQi4gUyP1WJYbILyKPB5WVfXrhk/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default Kood