import React from 'react'
import './video.css'

const Prillmodernrelationeasy = () => {
  return (
    <div className='.div'>
        <h1 className='.h1'>PRIL DO YOU THINK MODERN RELATIONSHIPS ARE EASY</h1>
        <iframe src="https://drive.google.com/file/d/1N2J03AfSwqEPn4NvBCrjwAyfIIuHls-r/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default Prillmodernrelationeasy