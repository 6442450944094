import React from 'react'
import './video.css'

const Showreel = () => {
  return (
    <div className='.div'>
        <h1 className='.h1'>SHOW REEL</h1>
        <iframe src="https://drive.google.com/file/d/1IC7IbwYVIOF4GXDqsxfzmkKYY1uZIK5w/preview" allow="autoplay"></iframe>
    </div>
  )
}

export default Showreel