/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import './css/about.css'
import './css/contact.css'
import './css/digital.css'
import './css/footer.css'
import './css/gif.css'
import './css/style.css'

const Home = () => {

  const videoLinks = [
    "upstox",
    "showreel",
    "muirak",
    "prillmodernrelationeasy",
    "sins",
    "prillmodernday",
    "kood",
    "evolvefilm",
    "v1resolve",
    "prillmaking",
  ]

  useEffect(() => {
    const container = document.querySelector(".fluid-container") as HTMLElement;
    const overlay = document.getElementById("overlay");

    const marqueeContent = document.querySelector("ul.marquee-content");


    if(!container || !overlay || !marqueeContent) return;

    setTimeout(() => {
      overlay.style.display = "block";
      container.style.pointerEvents = "none";
    }, 10000);

    const root = document.documentElement;

    root.style.setProperty("--marquee-elements", marqueeContent.children.length.toFixed(0));

    for (let i = 0; i < marqueeContent.children.length; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }

  }, [])

  const [openForm, setOpenForm] = React.useState(false);

  const videoRedirect = (link: any) => {
    window.location = link;
  };

  function off() {
    const container = document.querySelector(".fluid-container") as HTMLElement;
    const overlay = document.getElementById("overlay");
    if(!overlay || !container) return;
    overlay.style.display = "none";
    container.style.pointerEvents = "auto";
    container.style.position = "static";
    container.style.overflow = "visible";
  }

  const scroll = () => {
    //scroll to #about-container
    console.log("scrolling");
    const aboutContainer = document.getElementById("about-container");
    if(!aboutContainer) return;
    aboutContainer.scrollIntoView({ behavior: "smooth" });
  }

  const scrollTop = () => {
    //go to home page 
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className="home">
      <div id="overlay">
        <div id="pop-up">
          <span onClick={off} id="close-container"
            ><i className="fa-solid fa-xmark"></i></span>
          <div className="container">
            <div className="image-container">
              <img src="Images/nafo v.jpg" alt="" />
            </div>
            <div className="content-container">
              <h1>
                <span id="nafo">NAFO</span><br />
                <span id="international">INTERNATIONAL</span><br />
                <span id="film">FILM FESTIVAL</span><br />
                <span id="kuwait">KUWAIT</span><br />
                <span id="year">2022</span>
              </h1>
              <a target="_blank" id="link" href="https://forms.gle/1FxUx53Wr533jVpY8" rel="noreferrer">Submit the Film</a>
            </div>
          </div>
        </div>
      </div>
      <div className="fluid-container">
        <nav className="navbar navbar-expand-lg navbar-dark mb-3">
          <div className="container-fluid">
            <a className="navbar-brand" onClick={scrollTop}>
              <img src="logos/logo_dark.png" alt="" width="150" height="50" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" onClick={scroll}>About us</a>
                </li>
                <li className="nav-item" onClick={()=>setOpenForm(!openForm)}>
                  <a id="contactus" className="nav-link" href="#">Contact us</a>
                </li>
                <li className="nav-item">
                  <a target="_blank" className="nav-link" href="https://forms.gle/1FxUx53Wr533jVpY8" rel="noreferrer">Submit the film</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container-form" style={{ display: openForm?'grid':'none' }}>
          <img id="form-close" src="Images/icons8-close-50.png" onClick={()=>setOpenForm(false)} alt=''/>
          <div className="container-form-2">
            <form
              target="_blank"
              action="https://formsubmit.co/pravin@organiccinema.in"
              method="POST"
            >
              <input type="text" name="name" placeholder="Name" required />
              <input type="email" name="email" placeholder="Email" required />
              <input type="text" name="subject" placeholder="Subject" required />
              <input type="hidden" name="_next" />
              <textarea
                rows={3}
                cols={6}
                name="message"
                placeholder="Subject"
                required
              ></textarea>
              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="container-form-1">
            <img src="Images/cimg.jpg" alt="" />
          </div>
        </div>
        <div
          id="carouselExampleControls"
          className="carousel slide mb-3"
          data-bs-ride="carousel"
          data-pause="false"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-interval="1000">
              <img src="Images/mom.jpg" className="imgs d-block w-100" alt="" />
            </div>
            <div className="carousel-item" data-interval="1000" data-pause="false">
              <img src="Images/certificate 10x15.jpg" className="imgs d-block w-100" alt="" />
            </div>
            <div className="carousel-item" data-interval="1000" data-pause="false">
              <img src="Images/nafo.jpg" className="imgs d-block w-100" alt="" />
            </div>
            <div className="carousel-item" data-interval="1000" data-pause="false">
              <img src="Images/window seat poster 2.jpg" className="imgs d-block w-100" alt="" />
            </div>
            <div className="carousel-item"  data-interval="1000" data-pause="false">
              <img src="Images/the tale of santaa 01.jpg" className="imgs d-block w-100" alt="" />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
        <div className="gif-container">
          <div className="gif gif-container-1" onClick={()=>videoRedirect(videoLinks[0])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Organic Cinema Upstox The First Step.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Upstox</p>
          </div>
          <div className="gif gif-container-2" onClick={()=>videoRedirect(videoLinks[1])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/oc.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Showreel</p>
          </div>
          <div className="gif gif-container-3" onClick={()=>videoRedirect(videoLinks[2])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Muirak,The artist.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Muirak</p>
          </div>
          <div className="gif gif-container-4" onClick={()=>videoRedirect(videoLinks[3])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Prill Do you think Modern Relationships Are Easy.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Pril Modern</p>
          </div>
          <div className="gif gif-container-5" onClick={()=>videoRedirect(videoLinks[4])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/sins.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">SINS</p>
          </div>
          <div className="gif gif-container-6" onClick={()=>videoRedirect(videoLinks[5])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/prill ad.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Pril</p>
          </div>
          <div className="gif gif-container-7" onClick={()=>videoRedirect(videoLinks[6])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/koodu.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">KOOD</p>
          </div>
          <div className="gif gif-container-8" onClick={()=>videoRedirect(videoLinks[7])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/evolve.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Evolve</p>
          </div>
          <div className="gif gif-container-9" onClick={()=>videoRedirect(videoLinks[8])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/lic ipo.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Upstox women's day</p>
          </div>
          <div className="gif gif-container-10" onClick={()=>videoRedirect(videoLinks[9])}>
            <video style={{ width: '100%', height: '100%', objectFit: 'cover'}} autoPlay muted loop>
              <source src="videos/Prill Making.mp4" type="video/mp4" />
            </video>
            <p className="gif-label">Pril Making</p>
          </div>
        </div>
        <div className="marquee">
        <div className="clients">
          <h2>Our Clients</h2>
        </div>
        <div className="clients-container">
          <div>
            <div>
              <img src="./Brand Logos/1 viacom 18.png" alt="" />
            </div>
            <div>
              <img src="./Brand Logos/02 upstox logo.png" alt="" />
            </div>
            <div>
              <img src="./Brand Logos/03 Wazir+Logo+-+Horizontal w.png" alt="" />
            </div>
            <div>
              <img src="./Brand Logos/06 star logo.png" alt=''/>
            </div>
          </div>
          <div>
            <div>
              <img src="./Brand Logos/05 isha logo.png" alt="" />
            </div>
            <div>
              <img src="./Brand Logos/04 Forbes.png" alt=''/>
            </div>
            <div>
              <img src="./Brand Logos/7 network 18.png" alt=''/>
            </div>
            <div>
              <img src="./Brand Logos/08 paynet.png" alt="" />
            </div>
          </div>
          <div className="prill">
            <div>
              <img src="./Brand Logos/09 prill logo.png" alt="" />
            </div>
            <div>
              <img src="./Brand Logos/07 sahara logo.png" alt="" />
            </div>
            <div>
              <img src="./logos/rummy passion logo.png" alt="" />
            </div>
            <div>
              <img src="./Brand Logos/12 logo-fyi-white.png" alt="" />
            </div>
          </div>
        </div>
      </div>
        <a id="target" target="_blank" href='https://wa.me/8075187612?text=Hi, I would like to know more about Organic Cinema.Inc http://www.organiccinema.in' rel="noreferrer"><i id="whatsapp" className="fa-brands fa-3x fa-whatsapp"></i></a>
        <div id="about-container" className="about-container">
          <div className="about-container-1">
              <h1>ABOUT</h1>
          </div>
          <div className="about-container-2">
              <p>
                Organic Cinema Inc outpaces changes in today’s rapidly evolving
                media landscape by bringing reputation, advocacy, brand, and
                business results to new heights. Offering a comprehensive suite of
                solutions and technologies that define, connect, and protect brands,
                Organic Cinema Inc. creates content that is raw, original and
                organic to its core. We are devoted to innovation, creativity,
                passion and this is what drives us to work with a diverse group of
                people around the globe. Our specialty lies in blending traditional
                filmmaking, advertising and marketing techniques with path breaking
                technologies like VFX, Virtual Reality and Augmented Reality. We
                work on feature films, corporate films, commercials, music videos,
                short films, campaigns, live events and social media content. Also,
                collaborating with like-minded international and domestic partners
                enables us to bring great stories to the screen that resonate with
                audience, both domestic and international. Our imagination backed by
                your support will help us scale new heights.
              </p>
          </div>
        </div>
        <div className="magazine">
        <img className="brand-heading" src="./Images/Brands_1.png" />
        <div className="magazine-container">
          <div className="magazine-1">
              <div className="magazine-photos">
                <img src="./Advertisments Images/advertisment.jpg" alt="" />
                <h4>NOIR</h4>
                <p>From garage born startups to billion dollar MNCs and multi-faceted conglomerates, everyone needs  a slick, beautifully shot and edited corporate video. Yelniche necessities. Apart from corporate videos we also delve in infographics, brand strategies, brand makeovers and business presentations. Our team of multi expertise individuals will be at their best assisting you and your business bloom. Low-Corp deals with entrepreneurs, industries and corporates in fulfilling their needs in the best way possible.</p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/ocd.jpg" alt="" />
                <h4>OCD</h4>
                <p>Digital is the new frontier and OCD aims to be a major player in the digital content space in the very near future. With a team of  creative writers and story tellers, OCD concentrates on the specific needs of the generation Z- thrilling, exciting and moving content. Our sensibilities and preferences coupled with the freedom offered by streaming and OTT platforms means we can bring to life never seen before stories from epochs of humanity very few dared to venture into. Utilizing the advances in technology to bring forth fresh and exciting narratives is what we strive for. OCD also works closely with other departments in Organic Cinema in formulating digital specific campaigns and brand strategies.</p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/dead cat.jpg" alt="" />
                <h4>DEADCAT</h4>
                <p>The audio language or Oganic cinema, Deadcat deals with sound design, music production, voice overs, sound mixing, editing dubbing among others. A dedicated team of audio engineers, state of the art sound studio and equipment all come together to give our clients the best experience sound has to offer. Audiobooks and Podcasts were a niche segment thepast few years, but have been gaining momentum lately. DeadCat wishes to be a major player in these segments in the near future and we are raking huge strides in this endeavor.</p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/vast.jpg" alt="" />
                <h4>VAST</h4>
                <p>2D and 3D animation, motion capture, CGI,VFX, visual storytelling, pre visualization are just some of the things our team at Vast aces at. From breathtaking CGI imagery to realistic motion capture and life like 3D animation, we cater to high demand needs of our ever expanding clientele. Realistic 3D models for industrial demonstrations and our real estate ventures and our working CGI models for research are raken care of as well. Our pre visualization team answers the specific needs of various industries, reducing countless man hours and also saving up on precious resources.</p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/artist.jpg" alt="" />
                <h4>ARTIST.INC</h4>
                <p>Handpicked community of artists</p>
                <i>Learn more</i>
              </div>
          </div>
          <div className="magazine-2">
              <div className="magazine-photos">
                <img src="./Advertisments Images/PUPPET LOOP.jpg" alt="" />
                <h4>PUPPET LOOP</h4>
                <p>Puppet loop works on the fringes of creativity where art meets technology. We blend these two seemingly opposites to make unique experiences possible. We work on augmented reality[AR], virtual reality[VR] and hyper reality [HR]. With collaborating research from esteemed universities of the country, we are spearheading a new age revolution.From unique publicity campaigns to virtual launch events and contactless presentations, Puppet loop is at the forefront of novel ideas brought to life</p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/papyrus.jpg" alt="" />
                <h4>PAPYRUS</h4>
                <p>
                    An artistic magazine that narrows the gap between art and literature. Papyrus discusses cinematic insights from industry experts,evolution of cinema is an art form, its impact on our lives, what goes into making great cinema, the experiences behind the camera and other fun bits, while also showering some light on other niche forms of art. Papyrus also includes our print traditional marketing and advertising wings, working on publishing materials for publicity and branding. This one of a kind Magazine is quite literally art manipulated into pages with beautiful text complementing every context.
                </p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/yellow corp.jpg" alt="" />
                <h4>YELLOW CORP</h4>
                <p>CORPORATE VIDEOS </p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/ilive.jpg" alt="" />
                <h4>ILIVE</h4>
                <p>From FPS to MMORPGs and RTS games, iLive is the game design wing of Organic cinema. We pride ourselves as a cross platform game designers with reach expanding to PCs, consoles and mobile gaming devices. iLive also works closely with Puppet Loop to develop interactive and immersive VR and AR gaming solutions for consoles and VR devices.</p>
                <i>Learn more</i>
              </div>
              <div className="magazine-photos">
                <img src="./Advertisments Images/MUAGAN.jpg" alt="" />
                <h4>MUTAGAN</h4>
                <p>Mutagan deals with building our client’s online presence including creative ways to build, plan,and promote their websites, including website design and development,e-commerce web solutions and Organic Search Engine Optimization. With the advent of mobile, Mutagan’s android and ios wing concentrates on native app development UI and UX design and responsive mobile solutions Our expertise also extends to designing result oriented Digital Marketing, Social Media Marketing, PPC marketing. Influencer marketing campaigns across social media platforms and digital world.</p>
                <i>Learn more</i>
              </div>
          </div>
        </div>
        <div>
          <img className="brand-heading recent-heading" src="./Images/RECENTS.png" />
          <div className="recent-section">
              <div className="recent-div">
                <div className="card">
                    <img src="./Recent Images/1.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Inverted Japan Eye</h5>
                      <a href="one" target= "_blank" className="photo-recent b btn btn-primary">Show more</a>
                    </div>
                </div>
                <div className="card">
                    <img src="./Recent Images/2.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Panda Sins</h5>
                      <a href="two" target= "_blank" className="photo-recent b btn btn-primary">Show more</a>
                    </div>
                </div>
              </div>
              <div className="recent-div">
                <div className="card">
                    <img src="./Recent Images/3.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Neon Plant Library</h5>
                      <a href="three" target= "_blank" className="photo-recent b btn btn-primary">Show more</a>
                    </div>
                </div>
                <div className="card">
                    <img src="./Recent Images/shooterash_surreal_iceland_penguins_b6158488-c1b7-4812-89e8-cc89e87d0dbc.png" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Arrival Penguins</h5>
                      <a href="four" target= "_blank" className="photo-recent b btn btn-primary">Show more</a>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <div className="main-container">
          <div className="digital-container-1">
              <h2>SOCIAL</h2>
              <h3 id="odometer" className="odometer">5M+</h3>
          </div>
          <div className="digital-container-2">
              <img className="digital-logo" src="logos/logo_dark.png" />
              <div className="social-icons">
                <a className="scroll-anim" href="https://www.facebook.com/organiccinemainc"
                    ><i className="i-1 fa-brands fa-2x fa-facebook-f"></i><i className="i-2 fa-brands fa-2x fa-facebook-f"></i></a>
                <a className="scroll-anim" href="https://www.instagram.com/organic_cinema.inc/">
                <i className="i-1 fa-brands fa-2x fa-instagram"></i>
                <i className="i-2 fa-brands fa-2x fa-instagram"></i>
                </a>
              </div>
          </div>
          <div className="digital-container-3">
              <img className="digital-logo" src="logos/organic cinema india.png" />
              <div className="social-icons">
                <a className="scroll-anim" href="https://www.facebook.com/Organiccinemaincc"
                    ><i className="i-1 fa-brands fa-2x fa-facebook-f"></i><i className="i-2 fa-brands fa-2x fa-facebook-f"></i></a>
                <a className="scroll-anim" href="https://www.instagram.com/organic_cinema_india/">
                <i className="i-1 fa-brands fa-2x fa-instagram"></i>
                <i className="i-2 fa-brands fa-2x fa-instagram"></i>
                </a>
              </div>
          </div>
        </div>
        <div className="footer-container">
          <div className="footer-container-2 mb-3">
              <h2>THANK YOU</h2>
              <h3>
                "A formal bye. But our heart would Love to listen a<br />
                “Hello” from you, Soon."
              </h3>
          </div>
          <div className="footer-container-4">
              <div>
                <a className="scroll-anim" href="https://www.facebook.com/organiccinemainc">
                <i className="i-1 fa-brands fa-2x fa-facebook-f"></i><i className="i-2 fa-brands fa-2x fa-facebook-f"></i></a>
              </div>
              <div>
                <a href="https://www.instagram.com/organic_cinema.inc/" className="scroll-anim"
                    ><i className="i-1 fa-brands fa-2x fa-instagram"></i><i className="i-2 fa-brands fa-2x fa-instagram"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
